<template>
  <div class="question-detail" ref="question-detail">
    <div
      :class="showQuestion ? 'd-flex show-FullQuestion' : 'd-flex'"
      style="flex: 1;flex-direction:column;align-items: normal;justify-content: center;"
    >
      <div class="show-question">
        <div class="question-content-title">
          <div class="mb-2">
            <kbd v-if="order">{{ order }}</kbd>
          </div>
          <el-tooltip
            v-if="question.passage && question.passage.image"
            content="Some images may not display properly in this question"
            placement="top"
            effect="dark"
          >
            <i class="el-icon-warning warning-icon text-warning"></i>
          </el-tooltip>
          <div
            class="mt-2 mb-2"
            v-if="
              showPassage && question.passage && question.passage.content !== ''
            "
            v-html="changeColor(question.passage.content)"
          ></div>
          <div>
            <b v-html="changeColor(question.content)"></b>
            <el-tooltip
              v-if="question && question.image"
              content="Some images may not display properly in this question"
              placement="top"
              effect="dark"
            >
              <i class="el-icon-warning warning-icon text-warning"></i>
            </el-tooltip>
          </div>
        </div>
      </div>
      <div>
        <div v-if="showOptions">
          <ul
            class="ivy-question-options mt-2"
            v-if="question.type === 'single'"
          >
            <li
              class="option"
              v-for="(option, index) in question.options"
              :key="index"
            >
              <div
                v-if="question.answers"
                v-show="showAnswer"
                style="width:1.5rem"
              >
                <span class="text-success">
                  <i
                    v-show="isAnswer(question.answers, option.label)"
                    class="fas fa-check-circle"
                  ></i>
                </span>
              </div>
              <span style="margin-right: 4px">{{ `${option.label}) ` }}</span>
              <div
                class="optionText"
                v-html="changeColor(option.content)"
              ></div>
              <el-tooltip
                v-if="option && option.image"
                content="Some images may not display properly in this question"
                placement="top"
                effect="dark"
              >
                <i class="el-icon-warning warning-icon text-warning"></i>
              </el-tooltip>
            </li>
          </ul>
          <template v-else>
            <div class="mt-2" v-show="showAnswer">
              <b>Answer:</b>
              <span
                class="ml-1"
                v-for="(answer, index) in question.answers"
                :key="index"
              >
                {{ answer.answer[0] }}
                <span v-if="index < question.answers.length - 1">
                  ,
                </span>
              </span>
            </div>
          </template>
        </div>
        <template v-if="questionTags(question.question_tags)">
          <div v-show="showLabels">
            <div
              v-for="(tag_analysis, key, index) in questionTags(
                question.question_tags
              )"
              :key="index"
              class="pb-2"
            >
              <b>{{ key }}</b
              >:
              <el-tag
                v-for="tag in tag_analysis"
                :key="tag.id"
                type="success"
                size="small"
                class="ivy-tag"
              >
                <b>{{ tag.name }}</b>
              </el-tag>
            </div>
          </div>
        </template>
        <div
          v-if="question.explanations && question.explanations.length > 0"
          class="mt-2"
        >
          <div v-show="showExplanation">
            <b class="mb-1">Explanation:</b>
            <div
              v-for="explanation in question.explanations"
              :key="explanation.id"
            >
              <div class="video-box" v-if="isValidUrl(explanation.explanation)">
                <iframe :src="setUrl(explanation.explanation)"></iframe>
              </div>
              <div v-else v-html="explanation.explanation"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import moment from "moment";
import _ from "lodash";
import SAT from "@/apis/sat.js";
import Common from "@/mixins/common.js";

export default {
  components: {},
  mixins: [Common],
  props: [
    "question",
    "order",
    "search",
    "showPassage",
    "showQuestion",
    "showOptions",
    "showAnswer",
    "showExplanation",
    "showLabels"
  ],
  data() {
    return {};
  },
  computed: {},
  watch: {
    // showPassage() {
    //   this.initMathJaxPlugin();
    // },
    // showExplanation() {
    //   this.initMathJaxPlugin();
    // },
    // showAnswer() {
    //   if (this.showAnswer) {
    //     this.showOptions = true;
    //   }
    // },
    // showOptions() {
    //   this.initMathJaxPlugin();
    // },
    // showQuestion() {
    //   this.initMathJaxPlugin();
    // },
    // question() {
    //   this.initMathJaxPlugin();
    // }
  },
  mounted() {
    // this.initMathJaxPlugin();
  },
  methods: {
    isValidUrl(string) {
      string = this.setUrl(string);
      try {
        new URL(string);
        return true;
      } catch (err) {
        return false;
      }
    },
    setUrl(string) {
      string = string.replace(/<[^>]+>/g, "");
      string = string.replace("watch?v=", "embed/");
      return string;
    },
    correctRate(rate) {
      if (Number(rate) > -1) {
        return (Number(rate) * 100).toFixed(0) + "%";
      } else {
        return "-";
      }
    },
    questionTags(tags) {
      let questionTags = null;
      if (tags) {
        questionTags = _.groupBy(tags, "type");
      }
      return questionTags;
    },
    isAnswer(answers, answer) {
      let isAnswer = false;
      answers.forEach(e => {
        if (e.answer && e.answer.length > 0) {
          e.answer.forEach(item => {
            if (item.indexOf(answer) > -1) {
              isAnswer = true;
            }
          });
        }
      });
      return isAnswer;
    },
    moment() {
      return moment();
    },
    changeColor(result) {
      if (this.search) {
        let replaceReg = new RegExp(this.search, "ig");
        let replaceString = `<span style="background-color: var(--themeColor);color:white;padding:0 0.1rem">${this.search}</span>`;
        result = result.replace(replaceReg, replaceString);
      }
      return result;
    }
  }
};
</script>

<style scoped>
.video-box {
  max-width: 100%;
  max-height: 300px;
  aspect-ratio: 21 / 9;
}
.video-box iframe {
  width: 100%;
  height: 100%;
}
@font-face {
  font-family: DroidSerif;
  src: url("https://ivy-way.s3.ap-northeast-1.amazonaws.com/fonts/DroidSerif.ttf");
}
.question-detail {
  font-family: DroidSerif;
}
::v-deep .question-content-title img,
::v-deep .show-passage img {
  display: none;
}
::v-deep .show-FullQuestion .question-content-title img {
  display: block;
  max-width: 400px;
}
::v-deep .el-popper {
  width: 450px !important;
}
.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
@font-face {
  font-family: DroidSerif;
  src: url("https://ivy-way.s3.ap-northeast-1.amazonaws.com/fonts/DroidSerif.ttf");
}
.ivy-question {
  font-family: DroidSerif;
}
::v-deep .el-button {
  margin: 0 0.3rem;
}
.passage-title {
  position: absolute;
  border: 1px solid #fefefe;
  background: var(--themeColor);
  /* font-size: 16px; */
  color: white;
  left: -2px;
  top: -15px;
  height: 34px;
  padding: 0 17px;
  line-height: 32px;
  border-radius: 5px 5px 0 0;
}
.question-content {
  position: absolute;
  right: -5px;
  top: -25px;
}
.question-content-title {
  /* font-size: 1.4rem; */
}
/* ::v-deep .question-content-title img {
  display: block;
  max-width: 60%;
  max-height: 250px;
  margin: 10px auto 20px;
} */
::v-deep .question-content-title p {
  margin: 0;
}
.remove-question {
  cursor: pointer;
  position: absolute;
  /* font-size: 18px; */
  right: -10px;
  top: -10px;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
}
.tpos {
  display: block;
  margin: 10px 0;
  padding: 10px 20px;
  text-decoration: none;
  cursor: pointer;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: #edf1f8;
  border-bottom: 2px solid #dadee5;
  color: #808080;
  transition: 0.2s 0s;
  border-radius: 5px;
}
.tpos:hover {
  box-shadow: 3px 5px 10px #aaa;
  color: var(--themeColor);
}
</style>
<style scoped>
.question-order {
  margin: 10px 0 10px 0;
  background-color: #ccc;
}
kbd {
  padding-right: 15px;
  padding-left: 15px;
}
.answer_content {
  justify-content: space-around;
}
.cover {
  height: calc(100vh - 200px);
}
.question-order {
  margin: 20px 0;
  background-color: #ccc;
}
.ivy-question {
  position: relative;
  overflow: visible;
  background-color: #f8f8f8;
}
.add-button {
  position: absolute;
  top: 50%;
}
.ivy-question .el-card {
  overflow: visible;
}
.ivy-question-options {
  /* font-size: 16px; */
  padding-left: 0px;
  margin-bottom: 0px;
  list-style: none;
}
.ivy-question-options .option {
  display: flex;
  /* font-size: 16px; */
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  padding: 0;
}
::v-deep .ivy-question-options .option p {
  margin-bottom: 0 !important;
}
.ivy-tag {
  margin: 5px;
}
.hidden {
  display: none;
}
::v-deep .el-card__body {
  padding: 15px;
}
::v-deep .MathJax .mrow {
  /* font-size: 1rem !important; */
}
::v-deep .highlight {
  background-color: var(--themeColor);
  color: white;
  padding: 0 0.1rem;
}
.is-warning {
  background-color: #c0c4cc;
}
.warning-icon.text-warning {
  font-size: 2rem;
}
</style>
